import React, { useEffect } from "react";
import AppBar from "./AppBar";
import { Container, Row, Col } from "react-bootstrap";
import Footer from "./Footer";
import AOS from "aos";
import "aos/dist/aos.css";

const Chitsfunds = () => {
  useEffect(() => {
    AOS.init({
      offset: 100, // Offset (in px) from the original trigger point
      delay: 500, // Values from 0 to 3000, with step 50ms
      duration: 3000, // Values from 0 to 3000, with step 50ms
      easing: "ease", // Default easing for AOS animations
      mirror: true, // Whether elements should animate out while scrolling past them
    });
  }, []);

  return (
    <>
      <AppBar />

      <div className="padding">
        <Container>
          <Row>
            <Col lg="12" className="py-3" data-aos="fade-left">
              <img
                src={require("../../assets/images/banner/chitfund.jpg")}
                className="img-fluid"
                alt="product name"
              />
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default Chitsfunds;
