import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import AppBar from "./components/AppBar";
import Footer from "./components/Footer";
import { Container, Row, Col } from "react-bootstrap";
import CountUp from "react-countup";
import { Buttons } from "../components/Buttons/Buttons";
import { useNavigate } from "react-router-dom";
// import Banner from './components/Banner';
const Home = () => {
  useEffect(() => {
    AOS.init({
      offset: 100, // Offset (in px) from the original trigger point
      delay: 500, // Values from 0 to 3000, with step 50ms
      duration: 3000, // Values from 0 to 3000, with step 50ms
      easing: "ease", // Default easing for AOS animations
      mirror: true, // Whether elements should animate out while scrolling past them
    });
  }, []);
  const navigate = useNavigate();
  const handlenavigate = () => {
    navigate("/products");
  };

  return (
    <>
      <div className="over">
        <AppBar />
        <img
          src={require("../assets/images/banner/banner_elevel.jpg")}
          className="img-fluid w-100"
          alt="product name"
          data-aos="flip-right"
        />
        {/* <Banner/> */}

        {/* <intro start */}
        <div className="padding">
          <Container>
            <Row>
              <Col lg="12">
                <h1 class=" bold text-center pb-5 lg-font">
                  {" "}
                  Welcome to GMR Crackers
                </h1>
              </Col>
              <Col lg="6" xs={12} className="py-3 align-self-center">
                <div data-aos="fade-right">
                  <h2 class=" bold pt-5">Diwali Best Crackers In sivakasi</h2>

                  <p class="regular">
                    We supply quality crackers at lowest price. Crackers are
                    available in different specifications as per the
                    requirements of the clients. We provide variety of
                    firecrackers including single and multi-sound crackers,
                    sparklers, ground chakkars, flower pots, twinkling stars,
                    pencils, fancy rockets, aerial and fancy fireworks, fancy
                    whistling varieties, amorces, chorsa garlands, atom crackers
                    and electric crackers. We are specialists in fireworks gift
                    boxes and we have variety of gift boxes. Crackers are
                    procured from reliable vendors and are known for low
                    emission of noise &amp; pollution. Our Crackers are
                    available in various size packs, it is suitable for all
                    types of occasions.{" "}
                  </p>
                  <p class="regular">
                    At GMR Crackers, customer satisfaction is our top priority.
                    Our friendly staff is always on hand to help you choose the
                    best crackers that suit your needs. With a reputation built
                    on trust, safety, and affordability, we have established
                    strong relationships with our suppliers to bring you the
                    very best in crackers.
                  </p>
                </div>
              </Col>
              <Col lg="6" className="py-3" data-aos="fade-left">
                <img
                  src={require("../assets/images/home+image.jpg")}
                  className="img-fluid"
                  alt="product name"
                />
              </Col>
            </Row>
          </Container>
        </div>

        {/* <intro end */}
        {/* products start */}
        <Container className="py-5 padding">
          <Row className="justify-content-center">
            <Col lg="12" xs={12} className="text-center py-3">
              <h2 className="bold">Our Products</h2>
              <p className="regular">
                Our motto is to make every festival celebration bright and
                safe.This, we bring out with our wide range of firecrackers.
                With over 200 varieties of crackers developed and marketed every
                year, we are among the most sought brands in the Sivakasi region
                and around the country. Our products are known for their safety
                and we take great efforts to ensure that all our orders are
                delivered in a standard time frame with an economical pricing.
              </p>
            </Col>
            <Col lg="4" md="4" xs="12" className="py-3 text-center">
              <div class="home-product" data-aos="flip-left">
                <div>
                  <img
                    src={require("../assets/images/bombs.jpg")}
                    className="img-fluid"
                    alt="product name"
                  />
                </div>
                <div className="home-product-name">
                  <h5 className="bold"> One Sound Crackers</h5>
                  <p className="regular">Kuruvi,Laxmi,Spider...</p>
                </div>
              </div>
            </Col>
            <Col lg="4" md="4" xs="12" className="py-3 text-center">
              <div class="home-product" data-aos="flip-left">
                <div>
                  <img
                    src={require("../assets/images/chakkars.jpg")}
                    className="img-fluid"
                    alt="product name"
                  />
                </div>
                <div className="home-product-name">
                  <h5 className="bold">Chakkars</h5>
                  <p className="regular">Chakkar Big,Asoka,Deluxe...</p>
                </div>
              </div>
            </Col>
            <Col lg="4" md="4" xs="12" className="py-3 text-center">
              <div class="home-product" data-aos="flip-left">
                <div>
                  <img
                    src={require("../assets/images/flowerpots.jpg")}
                    className="img-fluid"
                    alt="product name"
                  />
                </div>
                <div className="home-product-name">
                  <h5 className="bold"> Flower Pots</h5>
                  <p className="regular">Big,Small,Colorkoti...</p>
                </div>
              </div>
            </Col>
            <Col lg="4" md="4" xs="12" className="py-3 text-center">
              <div class="home-product" data-aos="flip-left">
                <div>
                  <img
                    src={require("../assets/images/wala.jpg")}
                    className="img-fluid"
                    alt="product name"
                  />
                </div>
                <div className="home-product-name">
                  <h5 className="bold"> Garlands</h5>
                  <p className="regular">Deluxe,Giant Garlands...</p>
                </div>
              </div>
            </Col>
            <Col lg="4" md="4" xs="12" className="py-3 text-center">
              <div class="home-product" data-aos="flip-left">
                <div>
                  <img
                    src={require("../assets/images/sparklers.jpg")}
                    className="img-fluid"
                    alt="product name"
                  />
                </div>
                <div className="home-product-name">
                  <h5 className="bold">Sparklers</h5>
                  <p className="regular">Red,Green,Electric...</p>
                </div>
              </div>
            </Col>
            <Col lg="4" md="4" xs="12" className="py-3 text-center">
              <div class="home-product" data-aos="flip-left">
                <div>
                  <img
                    src={require("../assets/images/rockets.jpg")}
                    className="img-fluid"
                    alt="product name"
                  />
                </div>
                <div className="home-product-name">
                  <h5 className="bold"> Rockets</h5>
                  <p className="regular">Baby Rockets,Lunik,Whistle...</p>
                </div>
              </div>
            </Col>
            <Col lg="4" md="4" xs="12" className="py-3 text-center">
              <div class="home-product" data-aos="flip-left">
                <div>
                  <img
                    src={require("../assets/images/fountain.jpg")}
                    className="img-fluid"
                    alt="product name"
                  />
                </div>
                <div className="home-product-name">
                  <h5 className="bold"> fountain</h5>
                  <p className="regular">Fun Zone, Photo Flash, Drone...</p>
                </div>
              </div>
            </Col>
            <Col lg="4" md="4" xs="12" className="py-3 text-center">
              <div class="home-product" data-aos="flip-left">
                <div>
                  <img
                    src={require("../assets/images/giftbox.jpg")}
                    className="img-fluid"
                    alt="product name"
                  />
                </div>
                <div className="home-product-name">
                  <h5 className="bold"> Gift Box</h5>
                  <p className="regular">20 Items, 30 Items...</p>
                </div>
              </div>
            </Col>
            <Col lg="12">
              <div className="text-center py-3" data-aos="fade-up">
                <Buttons
                  label={<>View More Products</>}
                  onClick={handlenavigate}
                ></Buttons>
              </div>
            </Col>
          </Row>
        </Container>
        {/* products end */}
        {/* paralax start */}
        <div className="counter padding bg-overlay z">
          <Container>
            <Row>
              <Col lg="3" xs={12} className="py-3">
                <div className="z" data-aos="fade-up">
                  <div className="counter-box text-center">
                    <CountUp end={15} delay={1.2} />
                    <span>+</span>
                  </div>
                  <div className="text-center bold text-white">
                    Years Experience
                  </div>
                </div>
              </Col>
              <Col lg="3" xs={12} className="py-3">
                <div className="z" data-aos="fade-down">
                  <div className="counter-box text-center">
                    <CountUp end={200} delay={1.2} />
                    <span>+</span>
                  </div>
                  <div className="text-center bold text-white">Products</div>
                </div>
              </Col>
              <Col lg="3" xs={12} className="py-3">
                <div className="z" data-aos="fade-right">
                  <div className="counter-box text-center">
                    <CountUp end={1000} delay={1.2} />
                    <span>+</span>
                  </div>
                  <div className="text-center bold text-white">Customers</div>
                </div>
              </Col>
              <Col lg="3" xs={12} className="py-3">
                <div className="z" data-aos="fade-left">
                  <div className="counter-box text-center">
                    <CountUp end={100} delay={1.2} />
                    <span>%</span>
                  </div>
                  <div className="text-center bold text-white">
                    Customer Satisfaction
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* paralax end */}
        <img
          src={require("../assets/images/banner/banner_nine.jpg")}
          className="img-fluid w-100"
          alt="product name"
          data-aos="flip-right"
        />
        {/* product banner start */}
        <div className="padding">
          <Container>
            <Row>
              <Col lg="6" className="py-3">
                <img
                  src={require("../assets/images/retail.png")}
                  className="img-fluid"
                  alt="product name"
                  data-aos="flip-right"
                />
              </Col>
              <Col lg="6" className="py-3">
                <img
                  src={require("../assets/images/wholesale.png")}
                  className="img-fluid"
                  alt="product name"
                  data-aos="flip-left"
                />
              </Col>
            </Row>
          </Container>
        </div>
        {/* product banner end */}
        {/* sale bg start*/}
        <div className="salebg ">
          <div className="sale">
            <Container>
              <Row>
                <Col lg="7" md="12" xs="12" className="py-3">
                  <div className="" data-aos="fade-right">
                    <h1 className="bold">GMR Crackers</h1>
                    <div className="regular">
                      <p>
                        We are the leading crackers wholesale manufacturers in
                        Sivakasi, We deliver crackers directly from Sivakasi at
                        affordable price.
                      </p>
                      <p>
                        We have a crackers warehouse where you can get 365 days
                        at an affordable price with the best discount.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col
                  lg="5"
                  md="12"
                  xs="12"
                  className="py-3 align-self-center text-center"
                >
                  <button
                    data-aos="fade-up"
                    className="normal-btn"
                    onClick={handlenavigate}
                  >
                    Shop Now . . . !
                  </button>
                </Col>
              </Row>
            </Container>
          </div>
        </div>

        {/* sale bg end*/}
        <Footer />
      </div>
    </>
  );
};

export default Home;
